<template>
  <div class="flex">
    <div class="sidebar-container">
      <div class="menu">
        <ul class="menu-items">
          <li class="menu-title current">
            tools
          </li>
          <li class="" @click="handleCDNClick">
            CDN Cache Refresh
          </li>
          <li class="active">Friendly URLs</li>
          <li class="" @click="handleQRCodeClick">QR Codes</li>
        </ul>
      </div>
    </div>

    <!-- SPINNER OVERLAY -->
    <div class="overlay-spinner" v-if="spinnerOverlay">
      <svg class="spinner" viewBox="0 0 50 50">
        <circle
          class="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          stroke-width="5"
        ></circle>
      </svg>
    </div>
    <!-- SPINNER OVERLAY -->

    <div class="page-container width-full" v-else>
      <div class="subpage-content">
        <div class="content-container p-0 w1000">
          <section>
            <div class="content-band mt-60">
              <h2 class="semibold font-iop-drk-blue">
                Create and Manage Friendly URLs
              </h2>
              <p>
                Friendly URLs are short, easy to remember custom links. They can
                be used in print material, on social media, in newsletters, on
                pens, or billboards-- wherever your campaign takes you.
              </p>
              <div class="gray-250 p-40 rounded-12 flex flex-wrap width-full">
                <div class="wpc30 pr-40 pt-10">
                  <h3 class="semibold font-iop-drk-blue">Select a website</h3>
                  <p class="mt-0">Create and delete Friendly Urls</p>
                </div>
                <div class="wpc70 width-full" v-if="filters != ''">
                  <div class="inline-text-field-container">
                    <div class="select-container chevron border-bottom border-gray-600">
                      <select
                        v-model="selectedDomain"
                        class="ds-select-field rounded-4 hover-gray-300"
                        @change="test($event)"
                        style="-webkit-appearance: menulist;"
                      >
                        <option class="option" value="null" disabled selected>
                          Select a website to see your current Friendly URLs
                        </option>
                        <option
                          class="option"
                          v-for="item in filters"
                          v-bind:key="item.siteName"
                          :value="item.siteDomain"
                        >
                          {{ item.siteDomain }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="inline-text-field-container wpc70" v-else>
                  <div class="gray-50 p-5 rounded-8 flex flex-wrap">
                    <div class="wpc20 blue-200 dot m-10">
                      <div class="bell-icon semibold font-iop-blue"></div>
                    </div>
                    <div class="wpc80 pl-10 pt-15">
                      There is no existing websites with friendly URLs to show.
                      Don't see the website you're looking for? Submit a ticket
                      to IOP for help.
                    </div>
                  </div>
                </div>
                <div class="divider mt-20" v-if="selectedDomain != null">
                  <div>
                    <h4 class="semibold font-iop-blue mt-20">
                      {{ selectedDomain }}
                    </h4>
                  </div>
                </div>
                <CreateFriendlyURL
                  :selectedDomain="selectedDomain"
                  :fileredSites="filters"
                  :authorizedUser="authorizedUser"
                  v-if="selectedDomain != null"
                  ref="child"
                />
              </div>
            </div>
          </section>
        </div>
      </div>

      <SubFooter />
    </div>
  </div>
</template>

<script>
import CreateFriendlyURL from "../components/CreateFriendlyURL";
import SubFooter from "./SubFooter";
//import logs from "../assets/data/fakeAPI.json";
// import TopLevelLeftNav from "./TopLevelLeftNav";
import Vue from "vue";
export default {
  data: () => ({
    spinnerOverlay: true,
    dataArray: [],
    filters: [], // Initialize this to null to load sites list from OHID component
    selectedDomain: null,
    siteList: [],
    bus: new Vue(),
    ref: "",
    authorizedUser: false,
    //showQRCode: false
    //testData: logs,
  }),
/*   created: function() {
    if (
      window.location.href.includes("localhost") ||
      window.location.href.includes("stg")
    ) {
      this.showQRCode = true
    }
  }, */
  mounted() {
    this.ref = this.$refs;
    // load this method to retrieve user roles and domain from OHID component
    this.loadUserSiteData();
  },
  methods: {
    test(event) {
      this.ref.child.createList(event.target.value);
      // details should close when selecting another domain
      document.querySelector("details").removeAttribute("open");
    },
    handleCDNClick: function() {
      this.$router.push("/tools/cdn-cache-refresh");
    },
    handleQRCodeClick: function() {
      this.$router.push("/tools/qr-code");
    },
    loadUserSiteData: function() {
      //To load the data from session
      //var userSiteData = this.testData;
      var userSiteData = JSON.parse(sessionStorage.getItem('userRoles'));
      if (userSiteData === null) {
        return setTimeout(() => {
          this.loadUserSiteData();
        }, 5000);
      } else {
        this.getUserData(userSiteData);
      }
    },
    getUserData: function(userSiteData) {
      /* Load user list from OHID component, if null an fake JSON list is sent as its list data!! 
         This will be updated after testing is complete!!
      */
      this.dataArray = userSiteData;
      this.spinnerOverlay = false;
      if (this.dataArray.length > 0) {
        this.filters = this.dataArray
          .filter(
            (a) =>
              a.siteDomain != "" &&
              (a.userRole == "Manager" ||
                a.userRole == "Reviewer" ||
                a.userRole == "Editor" ||
                a.userRole == "Administrator")
          )
          .sort((a, b) => a.siteDomain.localeCompare(b.siteDomain));
      } else {
        this.filters = [];
      }
    },
  },
  components: {
    // TopLevelLeftNav,
    SubFooter,
    CreateFriendlyURL,
  },
  watch: {
    selectedDomain: function(currentValue) {
      let currentDomainObjectArray = this.filters.filter(
        (site) => site.siteDomain === currentValue
      );
      let currentDomainObject = currentDomainObjectArray[0];
      let userRole = currentDomainObject.userRole;
      if (userRole === "Manager" || userRole === "Administrator") {
        this.authorizedUser = true;
      } else {
        this.authorizedUser = false;
      }
      console.log(
        "current user has access to selected domain?",
        this.authorizedUser
      );
    },
  },
};
</script>
<style scoped>
/* .select-container.chevron::after {
  content: "";
  position: absolute;
  top: 24px;
  right: 20px;
  width: 14px;
  height: 14px;
  background: url(../assets/images/SVG/chevron-down.svg) no-repeat !important;
  cursor: pointer;
} */

.dot {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: inline-block;
}

.wpc65 {
  max-width: 65%;
}
.pt-15 {
  padding-top: 15px;
}

.bell-icon {
  height: auto;
  margin: 13px;
  padding: 13px;
  position: relative;
  background: url(../assets/images/SVG/bell-icon.svg) no-repeat !important;
}

/* .full-w {
  width: 100%;
} */
.overlay-spinner {
  background-color: rgba(0, 0, 0, 0.5);
  color: #666666;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 5000;
  top: 0;
  left: 0;
  float: left;
  text-align: center;
  padding-top: 25%;
  opacity: 0.8;
}
/* //////////////////////
    Spinner
////////////////////// */
.spin {
  position: relative;
  margin-top: 15px;
  margin-right: 10px;
}
.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #f6f6f6;
}
.spinner .path {
  stroke: #b8b8b8;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>
