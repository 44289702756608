<template>
  <div class="mt-60 width-full">
    <main>
      <details :class="{ disabled: !authorizedUser }" v-if="authorizedUser" class="mb-10">
        <summary
          class="pointer pb-10 pt-15"
          :class="{
            'iop-drk-blue-100': authorizedUser,
            'gray-600': !authorizedUser,
          }"
        >
          <span class="semibold font-white pl-10">Create a Friendly URL</span>
        </summary>
        <div class="create-on-load" v-show="createSuccess">
          <div class="inline-text-field-container pl-30 pr-30 pt-20">
            <div class="ds-text-field drk">
              <input
                id="longurl"
                type="text"
                v-model="form.originalURL"
                class="ds-text-field__input radius-4-top"
                autocomplete="off"
                required
              />
              <label for="longurl" class="ds-floating-label"
                >Enter the original URL, the one you want to shorten</label
              >
              <div class="field-line-ripple"></div>
            </div>
            <div
              class="font-red mb-0 m-negative45 small"
              v-if="inputFieldsEmpty"
            >
              <p>{{ inputFieldsEmpty }}</p>
            </div>
            <div
              class="font-red mb-0 m-negative45 small"
              v-if="originalURLEmpty && !inputFieldsEmpty"
            >
              <p>{{ originalURLEmpty }}</p>
            </div>
            <div
              class="font-red mb-0 m-negative45 small"
              v-if="domainMisMatch && !originalURLEmpty && !inputFieldsEmpty"
            >
              <p>{{ domainMisMatch }}</p>
            </div>
            <div
              class="font-red mb-0 m-negative45 small"
              v-if="
                createError &&
                  !domainMisMatch &&
                  !originalURLEmpty &&
                  !inputFieldsEmpty
              "
            >
              <p>{{ createErrorMessage }}</p>
            </div>
            <div class="flex">
              <div
                class="pt-20 pr-20 pl-20 font-iop-blue gray-250 radius-4-top-left"
                v-if="selectedDomain != null"
              >
                https://{{ selectedDomain }}/
              </div>
              <div class="ds-text-field drk mb-0 width-full">
                <input
                  id="friendlyurl"
                  type="text"
                  v-model="form.friendlyURL"
                  class="ds-text-field__input radius-4-top-right"
                  required
                />
                <label for="friendlyurl" class="ds-floating-label">
                  Enter the desired Friendly URL
                </label>
                <div class="field-line-ripple"></div>
              </div>
            </div>
            <div
              class="font-red mb-0 m-negative10 small"
              v-if="inputFieldsEmpty"
            >
              <p>{{ inputFieldsEmpty }}</p>
            </div>
            <div
              class="font-red mb-0 m-negative10 small"
              v-if="friendlyURLEmpty && !inputFieldsEmpty"
            >
              <p>{{ friendlyURLEmpty }}</p>
            </div>
            <div
              class="font-red mb-0 m-negative10 small"
              v-if="domainMisMatch && !friendlyURLEmpty && !inputFieldsEmpty"
            >
              <p>{{ domainMisMatch }}</p>
            </div>
            <div
              class="font-red mb-0 m-negative10 small"
              v-if="
                createError &&
                  !domainMisMatch &&
                  !friendlyURLEmpty &&
                  !inputFieldsEmpty
              "
            >
              <p>{{ createErrorMessage }}</p>
            </div>
            <p class="small mt-5">
              Must be all lower-case with no special characters
            </p>
          </div>
          <div class="flex justify-right pb-30 pr-30">
            <button
              id="button-js--primary"
              class="iop-drk-blue-100 radius-20 w300 pointer"
              @click="postURLList(selectedDomain)"
            >
              <div class="font-white semibold btn-text source-sans-pro">Submit</div>
            </button>
          </div>
        </div>
        <div
          class="inline-text-field-container pl-30 pr-30 pt-20 pb-30"
          v-show="isShow"
        >
          <div class="flex flex-wrap">
            <div class="wpc20 dot white">
              <div class="checkmark semibold font-iop-blue"></div>
            </div>
            <div class="wpc80 pl-20 mlr-auto">
              <h2 class="bold font-iop-blue">Success!</h2>
              <div style="white-space: pre-line">
                <div class="mtb-10">
                  <p>You have created:</p>
                  <a v-bind:href="this.createdSuccessLink" target="_blank" class="underline">{{ createdSuccessLink }}</a>
                </div>
                <div class="mtb-10">
                  <p>This link will now take users to:</p>
                  <a v-bind:href="this.createdSuccessLinkFrom" target="_blank" class="underline">{{ createdSuccessLinkFrom }}</a>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-right mt-10">
            <button
              id="button-js--primary"
              class="iop-drk-blue-100 radius-20 w200 pointer mr-20"
              @click="setShow()"
            >
              <div class="font-white bold btn-text source-sans-pro">Okay</div>
            </button>
            <!--<button
              id="button-js--primary"
              class="iop-drk-blue-100 radius-20 w200 pointer mr-20"
              @click="setShow()"
            >
              <div class="font-white bold btn-text">Make another one</div>
            </button>-->
          </div>
        </div>
      </details>
      <p v-else style="margin-bottom: 20px;">
        You don't have access to create Friendly URLs. Ask someone from your
        agency with L2/Content Approver permissions for help.
      </p>
      <ManageFriendlyURL
        :selectedDomain="selectedDomain"
        :fileredSites="fileredSites"
        :authorizedUser="authorizedUser"
        v-if="selectedDomain != null"
        ref="child"
      />
    </main>
  </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";
import ManageFriendlyURL from "../components/ManageFriendlyURL";
import { AuthConfig } from "../auth/auth.config.js";
export default {
  props: ["selectedDomain", "fileredSites", "authorizedUser"],
  data: () => ({
    bus: new Vue(),
    ref: "",
    //selectedDomain: ,
    siteList: [],
    form: {
      originalURL: "",
      friendlyURL: "",
      inputDomain: "",
    },
    createError: false,
    createErrorMessage: "",
    createSuccess: true,
    createSuccessMessage: "",
    createdSuccessLink: "",
    createdSuccessLinkFrom: "",
    domainMisMatch: "",
    originalURLEmpty: "",
    friendlyURLEmpty: "",
    inputFieldsEmpty: "",
    checkPdfLongUrl: false,
    checkHttpLongUrl: true,
    checkFriendlyPattern: true,
    isShow: false,
    keyExists: false,
  }),
  mounted() {
    this.ref = this.$refs;
    // let currentSite = this.fileredSites.filter((site) => {
    //   return site.siteDomain === this.selectedDomain;
    // });
    // let userRole = currentSite[0].userRole;
    // console.log("these is the role this user has for selected site", userRole);
    // if (userRole !== "Manager" || userRole !== "Administrator") {
    //   this.authorizedUser = true;
    // }
  },
  created() {
    console.log("Domain", this.selectedDomain);
    // let currentSite = this.fileredSites.filter((site) => {
    //   return site.siteDomain === this.selectedDomain;
    // });
    // let userRole = currentSite[0].userRole;
    // console.log("these is the role this user has for selected site", userRole);
    // if (userRole !== "Manager" || userRole !== "Administrator") {
    //   this.authorizedUser = true;
    // }
  },
  methods: {
    // handleDetailsClick: function(event) {
    //   console.log(
    //     "these are the sites this user has access to",
    //     this.fileredSites
    //   );
    //   let currentSite = this.fileredSites.filter((site) => {
    //     return site.siteDomain === this.selectedDomain;
    //   });
    //   let userRole = currentSite[0].userRole;
    //   console.log(
    //     "these is the role this user has for selected site",
    //     userRole
    //   );
    //   if (userRole !== "Manager" || userRole !== "Administrator") {
    //     this.authorizedUser = true;
    //   } else {
    //     event.preventDefault();
    //   }
    //   console.log(
    //     "this user has admin access to curent site",
    //     this.authorizedUser
    //   );
    // },
    setShow() {
      this.isShow = false;
      this.createSuccess = true;
      this.form.originalURL = "";
      this.form.friendlyURL = "";
      this.domainMisMatch = "";
      this.createErrorMessage = "";
      this.createList(this.selectedDomain);
    },
    createList(selectedDomain) {
      this.ref.child.getURList(selectedDomain);
    },
    isValidURL(longUrl, friendly) {
      //Check if longURL is Http or https
      var pattern1 = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z0-9\\_.%=~#+]*)*" + // port and path
        "(\\?[;&a-z\\_.~+#=-]*)?" + // query string
          "(\\[-a-z\\d_]*)?$",
        "i"
      );
      // path extension should not be pdf/jpg/png/gif for long and friendly Urls
      var pattern2 = new RegExp("^.*\\.(pdf|jpg|jpeg|png|gif)$");
      // Friendly url path should allow the following special characters only
      var pattern3 = new RegExp("^([a-zA-Z0-9_.~/+-]*)$");
      this.checkHttpLongUrl = !!pattern1.test(longUrl);
      console.log("Checkhttp", this.checkHttpLongUrl);
      this.checkPdfLongUrl = !!pattern2.test(longUrl);
      //var checkPdfFriendlyUrl = !!pattern2.test(friendly);
      this.checkFriendlyPattern = !!pattern3.test(friendly);
      if (
        this.checkHttpLongUrl == true &&
        this.checkPdfLongUrl == false &&
        this.checkFriendlyPattern == true
        //checkPdfFriendlyUrl == false
      ) {
        return true;
      }
      return false;
    },
    isKeyExists: function(obj, key) {
      if (obj[key] == undefined) {
        return false;
      } else {
        return true;
      }
    },
    checkExistingUrl: async function(data) {
      for (var key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          console.log(key + " -> " + data[key]);
        }
        this.siteList = data[key];
      }
      return this.siteList;
    },
    postURLList: async function(selectedDomain) {
      console.log(
        "URLs are",
        this.form.originalURL,
        this.form.friendlyURL,
        selectedDomain
      );
      //First clearing the error Msgs
      if (this.domainMisMatch || this.originalURLEmpty || this.friendlyURLEmpty || this.inputFieldsEmpty) {
        this.domainMisMatch= "";
        this.originalURLEmpty= "";
        this.friendlyURLEmpty= "";
        this.inputFieldsEmpty= "";
      }
      /*Get the domain of originalURL and check if originalURL and friendlyURL domain(s) are same*/
      // Shorten URL
      if (this.form.originalURL != "" && this.form.friendlyURL != "") {
        var precedeFriendlyWithSlash = this.form.friendlyURL.startsWith("/");
        // Precede friendly URl with forward slash if forward slash is not entered by the user
        if (precedeFriendlyWithSlash === false) {
          this.form.friendlyURL = "/" + this.form.friendlyURL;
        }
        var checkLongURL = this.isValidURL(
          this.form.originalURL.trim(),
          this.form.friendlyURL.trim()
        );
        if (checkLongURL === true) {
          var longUrlsource = this.form.originalURL.split("//")[1];
          var longUrlDomain = longUrlsource.split("/")[0];
          console.log("Long URL Domain", longUrlDomain);
          var longUrl = longUrlsource.substring(longUrlsource.indexOf("/"));
          console.log("Long URL", longUrl);
          if (longUrlDomain === selectedDomain) {
            // Check if URL already exists in the system
            var responseData = await this.getFriendlyURL(selectedDomain);
            if (responseData.status === 200) {
              console.log("Response Data", responseData.data);
              //Retrieve list of URLs for selected domain
              var urlExists = await this.checkExistingUrl(responseData.data);
              console.log("URL exists", urlExists);
              if (urlExists != null) {
                this.keyExists = this.isKeyExists(
                  urlExists,
                  this.form.friendlyURL
                );
                console.log(
                  "Check for the non-existing key, is key exists > " +
                    this.keyExists
                );
              }
              //create friendly url only when the url doesnt exist in the system
              if (!this.keyExists) {
                var postLongURL = await this.postFriendlyURL(
                  this.form.friendlyURL.trim(),
                  longUrl.trim(),
                  selectedDomain
                );
                if (postLongURL.status === 201) {
                  console.log("Post Response Status", postLongURL.data);
                  this.createError = false;
                  this.createSuccess = false;
                  this.isShow = true;
                  this.createdSuccessLink = "https://" + selectedDomain + this.form.friendlyURL;
                  this.createdSuccessLinkFrom = "https://" + selectedDomain + longUrl;
                  //console.log('urlLink', urlLink);
                  //this.createSuccessMessage = postLongURL.data.message;
                  /*this.createSuccessMessage =
                    "You have created:" + 
                    "\n" +
                    "https://" + selectedDomain + this.form.friendlyURL +
                    "\n" +
                    "\n" +
                    "This link will now take users to:" +
                    "\n" +
                    "https://" +
                    selectedDomain +
                    longUrl; */
                } else if (postLongURL.status === 400) {
                  console.log("Cannot Post-400 Error", postLongURL.data.error);
                  this.domainMisMatch = false;
                  this.createError = true;
                  this.createErrorMessage = postLongURL.data.error;
                } else {
                  console.log("Something went wrong");
                  this.domainMisMatch = false;
                  this.createError = true;
                  this.createErrorMessage =
                    "POST Error - CORS: Something went wrong!!";
                }
              } else {
                this.domainMisMatch = false;
                this.createError = true;
                this.createErrorMessage =
                  "Sorry, this friendly URL already exists. Manage existing friendly URLs below";
              }
            } else {
              console.log("Fetch Error");
              this.createError = true;
              this.createErrorMessage = "Something went wrong!!";
            }
          } else {
            this.inputFieldsEmpty = "";
            this.originalURLEmpty = "";
            this.friendlyURLEmpty = "";
            this.domainMisMatch =
              "The domain name " +
              selectedDomain +
              " of the original URL and the friendly URL should be same. Try again, choosing a different URL to shorten.";
          }
        } else if(this.checkFriendlyPattern == false && this.checkHttpLongUrl == true) {
           //Error MSG only for Friendly URl for the chars
          this.inputFieldsEmpty = "";
          this.originalURLEmpty = "";
          this.friendlyURLEmpty = "Sorry, the friendly URL cannot contain special characters like !@#$%^&*(). Try again, choosing a different URL to shorten";
        } else if(this.checkHttpLongUrl == false && this.checkFriendlyPattern == false) {
           //Error MSG for both Original and Friendly URl for the chars
          this.inputFieldsEmpty = "";
          this.originalURLEmpty = "";
          this.friendlyURLEmpty = "";
          this.domainMisMatch = "Sorry, the original URL cannot contain special characters like !@$%^&*(), and cannot be a link to a file, like a PDF, jpeg, jpg, gif. The friendly URL cannot contain special characters like !@#$%^&*(). Try again, choosing a different URL to shorten";
        } else if(this.checkPdfLongUrl == true || this.checkHttpLongUrl == false) {
          //Error MSG only for original URl for pdf/jpeg and the chars
          this.inputFieldsEmpty = "";
          this.friendlyURLEmpty = "";
          this.originalURLEmpty = "Sorry, the original URL cannot contain special characters like !@$%^&*(), and cannot be a link to a file, like a PDF, jpeg, jpg, gif. Try again, choosing a different URL to shorten";
        } 
      } else if (this.form.originalURL == "" && this.form.friendlyURL == "") {
        this.originalURLEmpty = "";
        this.friendlyURLEmpty = "";
        this.inputFieldsEmpty =
          "Oops! Fill in both the URL you want to shorten and the new friendly URL. ";
      } else if (this.form.friendlyURL == "") {
        this.inputFieldsEmpty = "";
        this.originalURLEmpty = "";
        this.friendlyURLEmpty = "Oops!! Fill in Friendly URL. ";
      } else if (this.form.originalURL == "") {
        this.inputFieldsEmpty = "";
        this.friendlyURLEmpty = "";
        this.originalURLEmpty = "Oops!! Fill in Original URL. ";
      } else {
        this.domainMisMatch =
          "Oops!! Something went wrong please try after sometime!!";
      }
    },
    postFriendlyURL: async (friendlyUrl, longUrl, sourceDomain) => {
      let idToken = sessionStorage.getItem("userIDtoken");
      let tokenExpired = sessionStorage.getItem("tokenExpiresIn");
      //let apiPath = sessionStorage.getItem("apiPath");
      let apiPath = AuthConfig.getCurrentAuthConfig().apiGateWay;
      if (Date.now() >= tokenExpired * 1000) {
        let ohidURL = sessionStorage.getItem("ohidUrl");
        window.open(ohidURL + "/pkmslogout", "_self").focus();
      } else {
        let config = {
          headers: {
            Authorization: idToken,
          },
        };
        try {
          const postResponse = await axios.post(
            apiPath + "/friendlyurl/" + sourceDomain,
            {
              // submit data with lowercase
              friendlyUrl: friendlyUrl.toLowerCase(),
              longUrl: longUrl.toLowerCase(),
            },
            config
          );
          if (postResponse.status == 201) {
            return postResponse;
          }
          return postResponse;
        } catch (err) {
          if (err.postResponse) {
            return err.postResponse.status;
          } else if (err.response) {
            return err.response;
          } else {
            console.log("CORS error", err);
            return err;
          }
        }
      }
    },
    getFriendlyURL: async (selectedDomain) => {
      let idToken = sessionStorage.getItem("userIDtoken");
      let tokenExpired = sessionStorage.getItem("tokenExpiresIn");
      //let apiPath = sessionStorage.getItem("apiPath");
      let apiPath = AuthConfig.getCurrentAuthConfig().apiGateWay;
      if (Date.now() >= tokenExpired * 1000) {
        let ohidURL = sessionStorage.getItem("ohidUrl");
        window.open(ohidURL + "/pkmslogout", "_self").focus();
      } else {
        let config = {
          headers: {
            Authorization: idToken,
          },
        };
        try {
          const response = await axios.get(
            apiPath + "/friendlyurl/" + selectedDomain,
            config
          );
          if (response.status == 200) {
            // for debugging only
            return response;
          }
          return response;
        } catch (err) {
          if (err.response) {
            return err.response.status;
          } else {
            console.log("CORS ERROR", err);
            return err;
          }
        }
      }
    },
  },

  components: {
    ManageFriendlyURL,
  },
};
</script>
<style scoped>
/* details > summary::after {
  content: "+";
  font-size: 38px;
  color: #fff;
  top: 5px;
  right: 22px;
}
details.disabled > summary::after {
  content: "";
}

details.disabled {
  pointer-events: none;
}

details > summary::marker {
  content: "";
}

summary:hover {
  background: var(--iop-drk-blue-800);
}

summary span {
  vertical-align: super;
}

details {
  max-width: none;
  width: auto;
}

details[open] summary {
  background: var(--iop-drk-blue-800);
}

details[open] {
  background: var(--blue-200);
  margin: 0px auto;
}

.ds-text-field__input {
  border-bottom: 1px solid var(--iop-drk-blue-800) !important;
  padding-top: 22px !important;
}

.radius-4-top-left {
  border-radius: 4px 0 0 0;
}

.m-negative45 {
  margin-top: -45px;
}

.m-negative10 {
  margin-top: -10px;
}

.dot {
  height: 105px;
  width: 105px;
  border-radius: 50%;
  display: inline-block;
}
.checkmark {
  height: auto;
  margin: 33px;
  padding: 25px;
  position: relative;
  background-image: url(../assets/images/SVG/checkmark.svg);
  background-repeat: no-repeat;
}

.ds-text-field__input:focus ~ .ds-floating-label,
.ds-text-field__input:valid ~ .ds-floating-label {
  top: 25%;
  color: var(--iop-drk-blue-800);
}

.ds-text-field:not(.ds-text-field--disabled)
  .ds-text-field__input:focus
  ~ .field-line-ripple,
.ds-text-field:not(.ds-text-field--disabled)
  .ds-text-field__input:valid
  ~ .field-line-ripple {
  background: var(--iop-drk-blue-800);
} */
</style>
