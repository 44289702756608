<template>
  <div class="width-full">
    <div v-if="selectedDomain != null">
      <div v-if="this.siteList != null">
        <article v-for="(value, key) in sorted" v-bind:key="key">
          <main>
            <details>
              <summary class="gray-background">
                <h5 aria-hidden="true" class="pl-10 regular">
                  https://{{ selectedDomain + key }}
                </h5>
              </summary>
              <div class="pb-30 pt-30" v-if="confirmDelete[key]">
                <h3 class="font-iop-blue mlr-auto">
                  Are you sure you want to delete the following URL?
                </h3>
                <p class="text-center">https://{{ selectedDomain + key }}</p>
                <div class="flex justify-center mt-30">
                  <button
                    class="blue-200 radius-20 w100 pointer mr-10"
                    @click="cancel(key)"
                  >
                    <div class="font-blue btn-text font-iop-blue">Cancel</div>
                  </button>
                  <button
                    class="iop-drk-blue-100 radius-20 w200 pointer mr-20"
                    id="delete"
                    @click="deleteFriendlyURLItem(key, selectedDomain)"
                  >
                    <div class="font-white btn-text">Yes, delete this URL</div>
                  </button>
                </div>
              </div>
              <div class="pb-10" v-else>
                <div class="pt-5 pb-5 pl-20 pr-20">
                  <div class="bold font-red text-center" v-if="editError[key]">
                    <p>{{ editErrorMessage }}</p>
                  </div>
                  <div
                    class="bold font-iop-blue text-center mb-30"
                    v-else-if="editSuccess[key] && !editError[key]"
                  >
                    <!--                     <p class="text-center">{{ editSuccessMessage }}</p>
                    <button
                      class="iop-drk-blue-100 radius-20 pointer w200 mlr-auto"
                      id="reset"
                      @click="reloadList(selectedDomain)"
                    >
                      <div class="font-white bold btn-text">Okay</div>
                    </button> -->
                  </div>
                </div>
                <div class="faq__content flex grow">
                  <div class="pr-30 width-full">
                    <p class="small font-iop-blue m-0">This friendly url:</p>
                    <div class="ds-text-field mt-10 width-full">
                      <input
                        type="text"
                        @input="key = $event.target.value"
                        v-bind:value="key"
                        class="ds-text-field__input gray-200 radius-4-top"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="width-full">
                    <p class="small font-iop-blue m-0">Goes to:</p>
                    <div class="ds-text-field mt-10 width-full">
                      <input
                        type="text"
                        v-bind:value="value"
                        class="ds-text-field__input gray-200 radius-4-top"
                        disabled
                      />
                    </div>
                    <!-- <i class="fas fa-external-link-alt"></i> -->
                  </div>
                </div>
                <div class="pb-30">
                  <div class="flex justify-right mt-10">
                    <button
                      class="blue-200 radius-20 w200 pointer mr-10 testing-code"
                      @click="copyToClipboard(selectedDomain, key)"
                    >
                      <div class="icon">
                        <img
                          src="../assets/images/SVG/chain_link-blue-icon.svg"
                        />
                      </div>
                      <div
                        class="font-blue btn-text font-iop-blue"
                        v-if="urlCopied[key]"
                      >
                        URL Copied!
                      </div>
                      <div class="font-blue btn-text font-iop-blue" v-else>
                        Copy URL
                      </div>
                    </button>
                    <button
                      class="blue-200 radius-20 w200 pointer mr-10"
                      @click="testUrl(selectedDomain, key)"
                    >
                      <div class="icon">
                        <img
                          src="../assets/images/SVG/external_link-lite-icon.svg"
                        />
                      </div>
                      <div class="font-blue btn-text font-iop-blue">
                        Test URL
                      </div>
                    </button>
                    <button
                      class="iop-drk-blue-100 radius-20 w100 pointer mr-20"
                      id="delete"
                      @click="deleteConfirm(key)"
                      v-if="authorizedUser"
                    >
                      <div class="font-white btn-text">Delete</div>
                    </button>
                  </div>
                </div>
              </div>
            </details>
          </main>
        </article>
      </div>
      <div class="site-list-empty" v-else>
        <p>Your Friendly URL list is empty.</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";
import { AuthConfig } from "../auth/auth.config.js";
export default {
  props: ["selectedDomain", "fileredSites", "authorizedUser"],
  data: function() {
    return {
      form: {
        friendlyUrl: "",
        longUrl: "",
      },
      editSuccess: [],
      editSuccessMessage: "",
      editError: [],
      editErrorMessage: "",
      siteList: [],
      urlCopied: [],
      confirmDelete: [],
      // copyText: "Copy URL",
    };
  },
  created() {
    this.getURList(this.selectedDomain);

    // determine if user has access to delete site
    // let currentSite = this.fileredSites.filter((site) => {
    //   return site.siteDomain === this.selectedDomain;
    // });
    // let userRole = currentSite[0].userRole;
    // if (userRole !== "Manager" || userRole !== "Administrator") {
    //   this.authorizedUser = true;
    // } else {
    //   this.authorizedUser = false;
    // }
  },
   computed : {
    sorted(){     
      return Object.keys(this.siteList).sort().reduce((obj, key) => { obj[key] = this.siteList[key]; return obj; }, {});
    }
  }, 
  methods: {
    getFriendlyURLDic: async function(urlList) {
      var data = urlList;
      for (var key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          console.log(key + " -> " + data[key]);
        }
        this.siteList = data[key];
        console.log("Domain List", this.siteList);
      }
    },
    getURList: async function(selectedDomain) {
      //console.log("selected dropdown",event.target.value);
      var responseData = await this.getFriendlyURL(selectedDomain);
      if (responseData.status === 200) {
        console.log("Response Data", responseData.data);
        this.getFriendlyURLDic(responseData.data);
      } else {
        responseData = "";
        this.getFriendlyURL(responseData);
      }
    },
    getFriendlyURL: async (selectedDropDown) => {
      let idToken = sessionStorage.getItem("userIDtoken");
      let tokenExpired = sessionStorage.getItem("tokenExpiresIn");
      //let apiPath = sessionStorage.getItem("apiPath");
      let apiPath = AuthConfig.getCurrentAuthConfig().apiGateWay;
      if (Date.now() >= tokenExpired * 1000) {
        let ohidURL = sessionStorage.getItem("ohidUrl");
        window.open(ohidURL + "/pkmslogout", "_self").focus();
      } else {
        let config = {
          headers: {
            Authorization: idToken,
          },
        };
        try {
          const response = await axios.get(
            apiPath + "/friendlyurl/" + selectedDropDown,
            config
          );
          if (response.status == 200) {
            // for debugging only
            console.log("Get list of friendly URLs", response.data);
            return response;
          }
          return response;
        } catch (err) {
          if (err.response) {
            console.log("Something went wrong", err.response.status);
            return err.response.status;
          } else {
            console.log("CORS ERROR", err);
            return err;
          }
        }
      }
    },
    deleteConfirm: async function(key) {
      Vue.set(this.confirmDelete, key, 1);
    },
    cancel: async function(key) {
      Vue.set(this.confirmDelete, key, 0);
    },
    deleteFriendlyURLItem: async function(key, selectedDomain) {
      this.beforeConfirm = true;
      this.confirmDelete = false;
      var delLongURL = await this.deleteFriendlyURL(key, selectedDomain);
      if (delLongURL.status === 200) {
        console.log("Delete Response Status", delLongURL.data);
        Vue.set(this.editError, key, 0);
        Vue.set(this.editSuccess, key, 1);
        // this.editSuccessMessage = delLongURL.data.message;
        this.editSuccessMessage =
          "Success! This URL has been deleted. Please click 'Okay' to Refresh the list.";
        this.reloadList(selectedDomain);
      } else if (delLongURL.status === 400) {
        console.log("Cannot Delete-400 Error", delLongURL.data.error);
        console.log("key", key);
        Vue.set(this.editSuccess, key, 0);
        Vue.set(this.editError, key, 1);
        this.editErrorMessage = delLongURL.data.error;
        this.confirmDelete = [];
      } else {
        console.log("Something went wrong");
        Vue.set(this.editError, key, 1);
        this.editErrorMessage = "Something went wrong";
        this.confirmDelete = [];
      }
    },
    deleteFriendlyURL: async (friendlyUrl, sourceDomain) => {
      let idToken = sessionStorage.getItem("userIDtoken");
      let tokenExpired = sessionStorage.getItem("tokenExpiresIn");
      //let apiPath = sessionStorage.getItem("apiPath");
      let apiPath = AuthConfig.getCurrentAuthConfig().apiGateWay;
      if (Date.now() >= tokenExpired * 1000) {
        let ohidURL = sessionStorage.getItem("ohidUrl");
        window.open(ohidURL + "/pkmslogout", "_self").focus();
      } else {
        try {
          const delResponse = await axios.delete(
            apiPath + "/friendlyurl/" + sourceDomain,
            {
              headers: {
                Authorization: idToken,
              },
              data: { friendlyUrl: friendlyUrl },
            }
          );
          if (delResponse.status == 200) {
            return delResponse;
          }
          return delResponse;
        } catch (err) {
          if (err.delResponse) {
            return err.delResponse.status;
          } else if (err.response) {
            return err.response;
          } else {
            console.log("CORS error", err);
            return err;
          }
        }
      }
    },
    reloadList: async function(selectedDomain) {
      this.editErrorMessage = "";
      this.editSuccessMessage = "";
      this.urlCopied = [];
      this.confirmDelete = [];
      this.getURList(selectedDomain);
    },
    copyToClipboard: async function(selectedDomain, friendlyUrl) {
      let urlSelect = "https://" + selectedDomain + friendlyUrl;
      // create dummy input to copy the string array inside it
      var dummy = document.createElement("input");
      // Add it to the document
      document.body.appendChild(dummy);
      // Set its ID
      dummy.setAttribute("id", "dummy_id");
      // Output the array into it
      document.getElementById("dummy_id").value = urlSelect;
      // Select it
      dummy.select();
      // Copy its contents
      document.execCommand("copy");

      // this.copyText = "URL Copied!";

      // Display copied on the UI for the div
      Vue.set(this.urlCopied, friendlyUrl, 1);
      // Remove it as its not needed anymore
      document.body.removeChild(dummy);

      setTimeout(() => {
        // this so the text changes back
        Vue.delete(this.urlCopied, friendlyUrl);
      }, 3000);

      // setTimeout(function() {
      //   this.copyText = "Copy URL";
      // }, 3000);
    },
    testUrl: async function(selectedDomain, friendlyUrl) {
      let urlSelect = "https://" + selectedDomain + friendlyUrl;
      console.log("test", urlSelect);
      window.open(urlSelect, "_blank").focus();
    },
  },
};
</script>
<style scoped>
button#delete[disabled],
button#delete:disabled {
  pointer-events: none;
}
details > summary::marker {
  content: "";
}

details > summary::after {
  content: "";
  position: absolute;
  top: 29px;
  right: 20px;
  height: 14px;
  width: 14px;
  background: url(../assets/images/SVG/chevron-down.svg) no-repeat;
  cursor: pointer;
}

summary:hover {
  background: var(--blue-200);
}

details {
  max-width: none;
  width: auto;
}

details[open] summary {
  background: var(--blue-200);
}

details[open] {
  background: #fff;
  margin: 20px auto;
}

.ds-text-field__input {
  border-bottom: 1px solid var(--iop-drk-blue-800);
}

.icon {
  padding-left: 0px;
  margin-right: 5px;
}
</style>
